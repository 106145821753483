import React from 'react';

import { Container } from '@components/Layout/Container/Container';

import styles from './MessageBar.module.scss';

interface IProps {
  children: React.ReactNode;
}

export const MessageBar: React.FC<IProps> = ({ children }) => (
  <section className={styles.wrapper}>
    <Container>{children}</Container>
  </section>
);
