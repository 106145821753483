import classNames from 'classnames/bind';
import React from 'react';

import styles from './SmallTitle.module.scss';

const cx = classNames.bind(styles);

interface IProps {
  color: 'blue' | 'gray' | 'lightGray' | 'white';
  align?: 'left' | 'center' | 'right';
  className?: string;
  children: string;
}

export const SmallTitle: React.FC<IProps> = ({ color, align = 'center', className, children }) => (
  <strong className={cx('title', color, align, className)}>{children}</strong>
);
