import classNames from 'classnames/bind';
import React from 'react';

import { Text } from '@components/Text/Text';

import styles from './Feature.module.scss';

interface IProps {
  icon: JSX.Element;
  title: string | JSX.Element;
  description: string | JSX.Element;
  color?: 'light' | 'dark';
  maxWidth?: number;
  className?: string;
  iconMargin?: 'sm' | 'md';
}

const cx = classNames.bind(styles);

export const Feature: React.FC<IProps> = ({
  icon,
  title,
  description,
  color = 'dark',
  maxWidth,
  iconMargin = 'md',
}) => (
  <div className={cx('wrapper', color)} style={{ maxWidth: maxWidth ?? 290 }}>
    <div className={cx('iconWrapper', iconMargin)}>{icon}</div>
    <div className={styles.content}>
      <Text className={styles.title} size={20} tag="strong">
        {title}
      </Text>
      <Text
        align="center"
        className={styles.description}
        color={color === 'dark' ? 'gray500' : 'white'}
        opacity={color === 'dark' ? 100 : 80}
        size={16}
        tag="div"
      >
        {description}
      </Text>
    </div>
  </div>
);
